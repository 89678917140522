import React, { useEffect, useState } from 'react';
import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import Layout from '../shared/Layout/Layout';
import {
  GreetingsDS,
  BenefitsStrip,
  HowDoesBootcampWork,
  DsEmploymentsShortDescription,
  ProjectsAndReviews,
  ProjectsContent,
  PaymentAndGroups,
  PaymentContent,
  OfflineGroupsContent,
  EntranceInterview,
  AboutDSprofession,
  NewDetails,
} from '../widgets';
import {
  Questions,
  StudyTools,
  Teachers,
  Schedule,
  Structure,
  GraduatesContent,
} from '../widgets';

import { useGreetingsQuery } from '../app/queries/greetings.query';
import { useStudyToolsQuery } from '../app/queries/studyTools.query';
import { useEducationImagesQuery } from '../app/queries/educationImg.query';
import { useProjectsQuery } from '../app/queries/projects.query';
import { useScheduleImgQuery } from '../app/queries/scheduleImg.query';
import { useRoadmapImagesQuery } from '../app/queries/roadmapImg.query';
import data from '../pages-fsd/DatasciencePage/model/datascience.data';
import {
  getCourse,
  getEventData,
  getNearestGroups,
  getProgramGroups,
} from '../api/strapi';
import HeadMaker from '../HeadMaker';
import { useProgramQuery } from '../app/queries/program.query';
import { usePaymentQuery } from '../app/queries/payment.query';
import { useEcbWorksQuery } from '../app/queries/howecbworks.query';
import { usePnRQuery } from '../app/queries/projectsAndReviews.query';
import { useStudentsQuery } from '../app/queries/students.query';
import { useStudentsWorkQuery } from '../app/queries/studentsWork.query';
import { parseDate } from '../helpers/lib';
import ExamPreparation from '../shared/ExamPreparation/ExamPreparation';
import useScrollToAnchor from '../helpers/useScrollToAnchor';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'DS_Онлайн',
  program: 'DS Онлайн',
  tags: ['дс онлайн'],
};
const leadData = {
  question: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
  greeting: {
    ...mainData,
    eventName: 'Оставьте ваши контакты для связи',
    leadAction: 'Получить консультацию',
    leadBlock: 'Топ',
  },
  request: {
    ...mainData,
    eventName: 'Datascience',
    leadAction: 'Заявка на программу',
    leadBlock: 'Топ',
  },
  loan: {
    ...mainData,
    eventName: 'Datascience',
    leadAction: 'Заявка на рассрочку',
    leadBlock: 'Стоимость',
  },
  discount: {
    ...mainData,
    eventName: 'Datascience',
    leadAction: 'Оплатить',
    leadBlock: 'Стоимость',
  },
  groups: {
    ...mainData,
    eventName: 'Datascience',
    leadAction: 'Заявка на программу',
    leadBlock: 'Потоки',
  },
  structure: {
    ...mainData,
    eventName: 'Получить подробную программу',
    leadBlock: 'Структура программы',
    leadAction: 'Получить подробную программу',
  },
  examPrepare: {
    ...mainData,
    eventName: 'Получить подробную программу',
    leadBlock: 'Как поступить',
    leadAction: 'Подписка гайд',
  },
};

const PROGRAM = 'DS';
const LOCATION = 'ONLINE';

function DatascienceNew() {
  useScrollToAnchor(700);
  const [group, setGroup] = useState({});
  const [groups, setGroups] = useState([]);
  const [prepGroup, setPrepGroup] = useState(null);
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [nextEvent, setNextEvent] = useState();
  const [teachers, setTeachers] = useState([]);
  const { greetingsImgs } = useGreetingsQuery();
  const { logosData } = useStudyToolsQuery();
  const { educationImg } = useEducationImagesQuery();
  const { projectsData } = useProjectsQuery();
  const { scheduleImg } = useScheduleImgQuery();
  const { roadmapImg } = useRoadmapImagesQuery();
  const { programImgs } = useProgramQuery();
  const { paymentImgs } = usePaymentQuery();
  const { ecbWorksImgs } = useEcbWorksQuery();
  const { reviewsImgs } = usePnRQuery();
  const { studentsData } = useStudentsQuery();
  const { studentsWorkData } = useStudentsWorkQuery();

  useEffect(() => {
    const ac = new AbortController();

    sendYandexMetrikaEvent('mainProgramView');
    getProgramGroups(ac, PROGRAM, LOCATION).then((data) => {
      setGroups(data);
    });

    getNearestGroups(ac, PROGRAM, LOCATION).then((data) => {
      setGroup(data);
    });
    getCourse(ac, 'online_ds')
      .then((data) => setTeachers(data[0].teacher))
      .catch((e) => console.error(e));
    getProgramGroups(ac, 'PREPARE_DS').then(
      (groups) => groups && setPrepGroup(groups[0]),
    );
    return () => {
      ac.abort();
    };
  }, []);

  useEffect(() => {
    const ac = new AbortController();

    sendYandexMetrikaEvent('mainProgramView');
    getNearestGroups(ac, PROGRAM, LOCATION)
      .then((data) => {
        setGroup(data);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsGroupLoading(false);
      });

    getEventData('DOD_DS', ac).then(setNextEvent).catch(console.log);

    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="Datascience">
      <GreetingsDS
        {...data.greetings}
        theme="dark"
        extraLeadData={leadData.greeting}
        requestLeadData={leadData.request}
        group={group}
        labelTheme={'paleBlue'}
        isLoading={isGroupLoading}
      />
      <BenefitsStrip benefits={data.benefits} />
      <AboutDSprofession
        {...data.aboutProfession}
        imgIcon={programImgs['program/stonksImage']}
        image={educationImg['education/ds-professions']}
      />
      <NewDetails bg={'details'} {...data.details} />
      <HowDoesBootcampWork
        images={ecbWorksImgs}
        {...data.howDoesBootcampWork}
      />
      <Structure
        {...data.structure}
        bg={'dsstructure'}
        leadData={leadData.structure}
        programImgs={programImgs}
        program="ds"
      />
      <StudyTools {...data.tools} logosData={logosData} />
      <Schedule {...data.schedule} images={scheduleImg} />
      <Teachers
        bg={'teachers'}
        theme={'light'}
        {...data.teachers}
        teachers={teachers}
      />
      <DsEmploymentsShortDescription data={data.employmentDescription} />
      <ProjectsAndReviews {...data.projectsAndReviews} images={reviewsImgs}>
        <ProjectsContent {...data.projects} projectsData={projectsData} />
        <GraduatesContent
          {...data.graduates}
          data={{
            people: data.graduates.data.people,
          }}
          studentsData={studentsData}
          studentsWorkData={studentsWorkData}
          noBg
        />
      </ProjectsAndReviews>

      <PaymentAndGroups bg={'paymentdsgroups'}>
        <PaymentContent
          {...data.payment}
          imgs={paymentImgs}
          group={group}
          loanLeadData={leadData.loan}
          discountLeadData={leadData.discount}
        />
        <OfflineGroupsContent
          {...data.groups}
          groups={groups}
          requestLeadData={leadData.groups}
        />
      </PaymentAndGroups>
      <EntranceInterview {...data.entranceInterview} />

      <ExamPreparation
        {...data.examPrepare}
        extraLeadData={leadData.examPrepare}
        requestLeadData={leadData.examPrepare}
        forDummies={false}
        modalButton
        startDate={parseDate(prepGroup?.startDate)?.slice(1).join(' ')}
        priceData={prepGroup?.price}
        img={programImgs['program/prepareTitle']}
      />
      <Questions
        {...data.questions}
        extraLeadData={leadData.question}
        withoutBg
      />
    </Layout>
  );
}

export default DatascienceNew;
